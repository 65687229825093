import Styles from '@screens/landing-page-default/components/features/style';
import { StyledWrapperProps } from '@screens/landing-page-default/types';
import General from '@styles/general';

export default function Features({ bgColor, isDarkMode }: StyledWrapperProps) {
  return (
    <General.FullWrapper bgColor={bgColor} data-testid="features">
      <General.Wrapper padding top="none">
        <Styles.SubTitle width="320px" isDarkMode={isDarkMode}>
          Por que fazer parte do Whitebook?
        </Styles.SubTitle>
        <Styles.CardContainer>
          <Styles.Card isDarkMode={isDarkMode}>
            <img
              src={isDarkMode ? '/images/medfriday/ico-location-white.svg' : '/images/ico-location.svg'}
              alt="Whitebook localidades"
              width="63"
              height="77"
              loading="lazy"
            />
            <Styles.Feature isDarkMode={isDarkMode}>Presente em mais de 800 cidades em todo o Brasil.</Styles.Feature>
          </Styles.Card>
          <Styles.Card isDarkMode={isDarkMode}>
            <img
              src={isDarkMode ? '/images/medfriday/ico-clipboard-white.svg' : '/images/ico-clipboard.svg'}
              alt="Whitebook especialidades"
              width="60"
              height="73"
              loading="lazy"
            />
            <Styles.Feature isDarkMode={isDarkMode}>+9000 tópicos de todas as especialidades</Styles.Feature>
          </Styles.Card>
          <Styles.Card isDarkMode={isDarkMode}>
            <img
              src={isDarkMode ? '/images/medfriday/ico-user-white.svg' : '/images/ico-user.svg'}
              alt="Whitebook recomendação"
              width="66"
              height="70"
              loading="lazy"
            />
            <Styles.Feature isDarkMode={isDarkMode}>9 de 10 médicos recomendam o Whitebook</Styles.Feature>
          </Styles.Card>
          <Styles.Card isDarkMode={isDarkMode}>
            <img
              src={isDarkMode ? '/images/medfriday/ico-heart-white.svg' : '/images/ico-heart.svg'}
              alt="Whitebook satisfação"
              width="73"
              height="65"
              loading="lazy"
            />
            <Styles.Feature isDarkMode={isDarkMode}>+300mil usuários satisfeitos com o Whitebook</Styles.Feature>
          </Styles.Card>
        </Styles.CardContainer>
      </General.Wrapper>
    </General.FullWrapper>
  );
}
