import RemoteLoadOffers, { RemoteLoadOffers as RemoteLoadOffersType } from '@remote-data/load-offers';
import { OffersModel } from '@remote-data/load-offers/types';
import { loggerInfo } from '@shared/logger';
import { handleError } from '@use-cases/errors/error-handling';

export class UseCaseLoadOffers {
  constructor(private readonly remoteLoadOffers: RemoteLoadOffersType) {}

  async run(): Promise<OffersModel> {
    try {
      const response = await this.remoteLoadOffers.run();
      loggerInfo('UseCaseLoadOffers', 'run', { response });
      return response.body;
    } catch (error) {
      return handleError(error, 'UseCaseLoadOffers', [], 'Ops, parece que algo deu errado ao carregar as ofertas.');
    }
  }
}

export default new UseCaseLoadOffers(RemoteLoadOffers);
