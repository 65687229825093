import { useEffect, useState } from 'react';

import List from '@screens/landing-page-default/components/testimonials/list';
import Styles from '@screens/landing-page-default/components/testimonials/style';
import General from '@styles/general';
import { StyledWrapperProps } from '@screens/landing-page-default/types';

export default function Testimonials({ bgColor }: StyledWrapperProps) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const limit = List.length - 1;
  let timer = null;

  const handleNext = () => {
    clearTimeout(timer);
    if (currentIndex !== limit) {
      const increment = currentIndex + 1;
      setCurrentIndex(increment);
    } else {
      setCurrentIndex(0);
    }
  };

  const handlePrev = () => {
    clearTimeout(timer);
    if (currentIndex > 0) {
      const decrement = currentIndex - 1;
      setCurrentIndex(decrement);
    } else {
      setCurrentIndex(limit);
    }
  };

  const handleAutomatic = () => {
    timer = setTimeout(() => {
      handleNext();
    }, 10000);
  };

  useEffect(() => {
    handleAutomatic();
  });

  return (
    <General.FullWrapper bgColor={bgColor} data-testid="testimonials">
      <General.Wrapper>
        <Styles.SubTitleTestimonial>Veja o que dizem sobre o Whitebook</Styles.SubTitleTestimonial>

        {List.map((testimonial, index) => {
          if (index === currentIndex) {
            return (
              <div key={testimonial.id}>
                <Styles.Quote src="/images/quote.webp" width={93} height={60} alt="Quote" loading="lazy" />
                <Styles.Testimonial data-testid="testimonialText">{testimonial.text}</Styles.Testimonial>
                <Styles.Persona data-testid="testimonialCase">
                  {testimonial.name} | {testimonial.profession} - {testimonial.location}
                </Styles.Persona>
              </div>
            );
          }
          return null;
        })}

        <Styles.Navigation>
          <Styles.Control onClick={handlePrev} back data-testid="prevButton" />
          <Styles.Control onClick={handleNext} forward data-testid="nextButton" />
        </Styles.Navigation>
      </General.Wrapper>
    </General.FullWrapper>
  );
}
