import styled from 'styled-components';

import { device } from '@styles/media';
import { StyledWrapperProps } from '@screens/landing-page-default/types';

const SubTitleDownload = styled.h2<Partial<StyledWrapperProps>>`
  font-weight: normal;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: ${({ isDarkMode, theme }) => (isDarkMode ? theme.tokens.gray[100] : theme.tokens.blue[800])};
  max-width: 370px;
  padding-top: 100px;
  padding-bottom: 24px;
  margin: 0;

  @media ${device.mobile} {
    font-size: 26px;
    max-width: 220px;
    padding-top: 50px;
  }
`;

const DownloadWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  > img {
    height: auto;
    max-width: 532px;
    object-fit: contain;

    @media ${device.mobile} {
      align-self: center;
      max-width: 100%;
    }
  }

  @media ${device.mobile} {
    flex-direction: column;

    > img {
      object-fit: contain;
      height: 356px;
    }
  }
`;

const WrapDownloads = styled.div``;

const WrapLinks = styled.span`
  display: flex;
  align-items: center;

  @media ${device.mobile} {
    justify-content: space-between;
    max-width: 300px;
  }
`;

const Link = styled.span`
  img {
    max-width: 158px;
    height: 47px;
    margin-right: 24px;
  }

  @media ${device.mobile} {
    img {
      max-width: 124px;
      height: 36px;
      margin: 0;
    }
  }
`;

const Platform = styled.p<Partial<StyledWrapperProps>>`
  max-width: 264px;
  color: ${({ isDarkMode, theme }) => (isDarkMode ? theme.tokens.gray[100] : theme.tokens.blue[800])};
`;

export default {
  SubTitleDownload,
  DownloadWrapper,
  WrapDownloads,
  WrapLinks,
  Link,
  Platform,
};
