export default [
  {
    id: 1,
    text: 'Agradeço o excelente trabalho em tornar o Whitebook cada vez mais interessante e cheio de novidades. Estão de parabéns!',
    name: 'Dr. Nasser Calumbuana',
    profession: 'Médico',
    location: 'Luanda - Angola',
  },
  {
    id: 2,
    text: 'Adoro os apps de vocês, facilitam em muito a nossa vida, recém formados, e auxiliam bastante também os médicos mais antigos em algumas dúvidas, minha mãe também utiliza.',
    name: 'Dr. Ana Carolina de Oliveira Marques',
    profession: 'Médica',
    location: 'CE',
  },
  {
    id: 3,
    text: 'Muito obrigado, é uma honra poder contar com vocês na tomada de conduta do dia a dia. Aqui em Maringá os aplicativos já são uma unanimidade e fiz questão de convidar mais de 100 colegas médicos! Continuem assim! Parabéns!!',
    name: 'Dr. Fernando Padilha Barbosa',
    profession: 'Médico',
    location: 'Universidade Estadual de Maringá - PR',
  },
  {
    id: 4,
    text: 'Primeiro, quero parabenizar vocês pelo Whitebook e pela assistência prestada. Na minha opinião, possuem hoje o melhor programa médico disponível, pago ou não.',
    name: 'Dr. Reinaldo Alves',
    profession: 'Médico Generalista',
    location: 'MG',
  },
];
