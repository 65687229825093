import Styles from '@screens/landing-page-default/components/plans/components/feature-table/column/styles';
// eslint-disable-next-line prettier/prettier
import { FeatureColumnsProps } from '@screens/landing-page-default/components/plans/components/feature-table/column/types';

export default function PlanFeatureTableColumn({
  featureRow1,
  featureRow2,
  featureRow3,
  featureRow4,
  featureRow5,
  featureHighlight1,
  featureHighlight2,
}: FeatureColumnsProps): JSX.Element {
  return (
    <Styles.Column>
      <Styles.HorizontalLine />
      <Styles.Features>{featureRow1}</Styles.Features>
      <Styles.HorizontalLine />
      <Styles.FeaturesIcon>{featureRow2}</Styles.FeaturesIcon>
      <Styles.HorizontalLine />
      <Styles.FeaturesIcon>{featureRow3}</Styles.FeaturesIcon>
      <Styles.HorizontalLine />
      <Styles.FeaturesIcon>{featureRow4}</Styles.FeaturesIcon>
      <Styles.HorizontalLine />
      <Styles.Features>{featureRow5}</Styles.Features>
      <Styles.HorizontalLine />
      <Styles.FeaturesHighlight>{featureHighlight1}</Styles.FeaturesHighlight>
      <Styles.HorizontalLine />
      <Styles.FeaturesHighlight>{featureHighlight2}</Styles.FeaturesHighlight>
    </Styles.Column>
  );
}
