import styled from 'styled-components';
import { device } from '@styles/media';

const PlanContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export default {
  PlanContainer,
};
