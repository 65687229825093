import Copy from '@components/copy';
import Styles from '@components/footer/style';

export default function Footer() {
  return (
    <Styles.MainFooter>
      <Styles.WrapperFooter>
        <Styles.GroupLinks>
          <Styles.About>
            <Styles.Link as="a" href={process.env.NEXT_PUBLIC_PORTAL_AFYA} target="_blank" rel="noreferrer">
              Portal Afya
            </Styles.Link>
            <Styles.Link as="a" href={`${process.env.NEXT_PUBLIC_APP_URL}/quem-somos/`} rel="noreferrer">
              Quem somos
            </Styles.Link>
            <Styles.Link as="a" href="https://help.pebmed.com.br/hc/pt-br/" target="_blank" rel="noreferrer">
              Contato
            </Styles.Link>
            <Styles.Link
              as="a"
              href={`${process.env.NEXT_PUBLIC_APP_URL}/termos-de-uso-afya-whitebook/`}
              rel="noreferrer"
            >
              Termos de uso
            </Styles.Link>
            <Styles.Link as="a" href={`${process.env.NEXT_PUBLIC_APP_URL}/privacidade/`} rel="noreferrer">
              Diretrizes e política
            </Styles.Link>
            <Styles.Link as="a" href={`${process.env.NEXT_PUBLIC_APP_URL}/pedido-do-titular/`} rel="noreferrer">
              Pedido do titular
            </Styles.Link>
          </Styles.About>

          <Styles.Social>
            <Styles.Share
              as="a"
              href="https://www.facebook.com/whitebookoficial/"
              target="_blank"
              rel="noreferrer"
              data-testid="facebook-link"
            >
              <img
                src="/images/footer/social-icons/ico-facebook.svg"
                width="16"
                height="16"
                alt="Facebook Pebmed"
                loading="lazy"
              />
            </Styles.Share>
            <Styles.Share
              as="a"
              href="https://www.instagram.com/whitebookoficial/"
              target="_blank"
              rel="noreferrer"
              data-testid="instagram-link"
            >
              <img
                src="/images/footer/social-icons/ico-instagram.svg"
                width="16"
                height="16"
                alt="Instagram Pebmed"
                loading="lazy"
              />
            </Styles.Share>
            <Styles.Share
              as="a"
              href="https://twitter.com/AppWhitebook/"
              target="_blank"
              rel="noreferrer"
              data-testid="x-link"
            >
              <img src="/images/footer/social-icons/ico-x.svg" width="16" height="16" alt="X Pebmed" loading="lazy" />
            </Styles.Share>
            <Styles.Share
              as="a"
              href="https://www.youtube.com/channel/UCtrOK6S3hEPCeTI1KJUH8lg"
              target="_blank"
              rel="noreferrer"
              data-testid="youtube-link"
            >
              <img
                src="/images/footer/social-icons/ico-youtube.svg"
                width="16"
                height="16"
                alt="Youtube Pebmed"
                loading="lazy"
              />
            </Styles.Share>
            <Styles.Share
              as="a"
              href="https://www.linkedin.com/company/9331858"
              target="_blank"
              rel="noreferrer"
              data-testid="linkedin-link"
            >
              <img
                src="/images/footer/social-icons/ico-linkedin.svg"
                width="16"
                height="16"
                alt="Linkedin Pebmed"
                loading="lazy"
              />
            </Styles.Share>
          </Styles.Social>
        </Styles.GroupLinks>
        <Copy fontSize="10px" fontSizeXs="10px" padding="24px 0" />
      </Styles.WrapperFooter>
    </Styles.MainFooter>
  );
}
