// Swagger
// https://api-assinatura-homologacao.pebmed.com.br/docs/#/OFFERS/get_assinatura_gateways__gateway_name__offers

import { RemoteError } from '@remote-data/errors';
import AxiosHttpClient from '@remote-data/http-client';
import { HttpClient, HttpResponse, HttpResponseError, HttpStatusCode } from '@remote-data/http-client/types';
import { responseFromOffersModel } from '@remote-data/load-offers/adapters';
import { OffersFromAPI, OffersModel } from '@remote-data/load-offers/types';

const makeApiUrl = (path: string): string => `${process.env.NEXT_PUBLIC_API_ASSINATURA}${path}`;

export class RemoteLoadOffers {
  constructor(private readonly url: string, private readonly httpClient: HttpClient) {}

  async run(): Promise<HttpResponse<OffersModel>> {
    const httpResponse: HttpResponse<OffersFromAPI | HttpResponseError> = await this.httpClient.request({
      url: this.url,
      method: 'get',
    });
    if (HttpStatusCode.Ok === httpResponse.statusCode) {
      return responseFromOffersModel(httpResponse as HttpResponse<OffersFromAPI>);
    }
    throw new RemoteError(
      httpResponse.statusCode,
      (httpResponse.body as HttpResponseError).codigo,
      (httpResponse.body as HttpResponseError).mensagem,
    );
  }
}

export default new RemoteLoadOffers(makeApiUrl('/assinatura/gateways/iugu/offers/'), AxiosHttpClient);
