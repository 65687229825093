import { useState } from 'react';

import { ButtonOutlined, CheckMarkIcon, DeleteIcon } from '@pebmed/storybook-react';
import Styles from '@screens/landing-page-default/components/plans/components/column-free/style';
import PlanFeatureTableColumn from '@screens/landing-page-default/components/plans/components/feature-table/column/index';
import PlanFeatureTableHeader from '@screens/landing-page-default/components/plans/components/feature-table/header/index';
import { defaultTheme } from '@styles/theme';

export default function ColumnFree() {
  const [isMoreDetailsClickedFree, setisMoreDetailsClickedFree] = useState(false);

  const handleFreeButton = () => {
    window.location.href = `${process.env.NEXT_PUBLIC_WHITEBOOK_WEB_OLD}/cadastro`;
  };

  const checkMarkIcon = <CheckMarkIcon size={36} strokeColor={defaultTheme.tokens.pink[200]} />;
  const deleteIcon = <DeleteIcon size={30} strokeColor={defaultTheme.tokens.gray[1900]} />;

  return (
    <Styles.ColumnPlan>
      <Styles.PlanInfoDiv>
        <Styles.Type>Free</Styles.Type>
        <Styles.Price>Sem custo</Styles.Price>
        <Styles.BlankSpaceFree />
      </Styles.PlanInfoDiv>
      <Styles.DivBtn>
        <ButtonOutlined
          height="50px"
          width="118px"
          onClick={() => {
            handleFreeButton();
          }}
        >
          Selecionar
        </ButtonOutlined>
      </Styles.DivBtn>
      <Styles.BlankSpaceFeature />
      <Styles.FeatureDivDesktop>
        <PlanFeatureTableColumn
          featureRow1="Limitado"
          featureRow2={checkMarkIcon}
          featureRow3={checkMarkIcon}
          featureRow4={deleteIcon}
          featureRow5="+ 200"
          featureHighlight1="+ 90"
          featureHighlight2="+ 10"
        />
        <Styles.BtnReduzir onClick={() => setisMoreDetailsClickedFree(false)}>Reduzir</Styles.BtnReduzir>
      </Styles.FeatureDivDesktop>
      {isMoreDetailsClickedFree ? (
        <Styles.Div />
      ) : (
        <Styles.BtnMaisDetalhes onClick={() => setisMoreDetailsClickedFree(true)}>Mais detalhes</Styles.BtnMaisDetalhes>
      )}
      {isMoreDetailsClickedFree ? (
        <Styles.FeatureDivMobile>
          <Styles.FeatureTxtPlanColumn>Características do plano</Styles.FeatureTxtPlanColumn>
          <Styles.GridMobile>
            <Styles.GridItem>
              <PlanFeatureTableHeader
                featureRow1="Acesso"
                featureRow2="Medicamentos"
                featureRow3="Código CID, TUSS e SUS"
                featureRow4="Fluxogramas interativos"
                featureRow5="Prescrições"
                featureHighlight1="Condutas clínicas"
                featureHighlight2="Calculadoras e escores"
              />
            </Styles.GridItem>
            <Styles.GridItem>
              <PlanFeatureTableColumn
                featureRow1="Limitado"
                featureRow2={checkMarkIcon}
                featureRow3={checkMarkIcon}
                featureRow4={deleteIcon}
                featureRow5="+ 200"
                featureHighlight1="+ 90"
                featureHighlight2="+ 10"
              />
            </Styles.GridItem>
          </Styles.GridMobile>
          <Styles.BtnReduzir onClick={() => setisMoreDetailsClickedFree(false)}>Reduzir</Styles.BtnReduzir>
        </Styles.FeatureDivMobile>
      ) : (
        <Styles.BlankSpaceFeature />
      )}
    </Styles.ColumnPlan>
  );
}
