import { device } from '@styles/media';
import styled from 'styled-components';

export default {
  Copy: styled.span<{
    color?: string;
    fontSize?: string;
    fontSizeXs?: string;
    padding?: string;
    margin?: string;
    marginXs?: string;
    lineHeight?: string;
  }>`
    color: ${({ color }) => color};
    display: block;
    font-size: ${({ fontSize }) => fontSize};
    line-height: ${({ lineHeight }) => lineHeight};
    max-width: 100%;
    padding: ${({ padding }) => padding};
    margin: ${({ margin }) => margin};
    text-align: center;

    @media ${device.mobile} {
      font-size: ${({ fontSizeXs }) => fontSizeXs};
      margin: ${({ marginXs }) => marginXs};
    }
  `,
};
