import styled from 'styled-components';

import { device } from '@styles/media';

const MainFooter = styled.footer`
  @media ${device.desktop} {
    border: 1px solid ${({ theme }) => theme.tokens.gray[800]};
  }
  background: linear-gradient(180deg, rgba(244, 243, 246, 0) 0%, #f4f3f6 100%);
  margin-top: 20px;
  padding-bottom: '30px';
  width: 100%;

  img {
    max-width: 100%;
    height: auto;
  }

  @media ${device.mobile} {
    padding-bottom: '24px';
  }
`;

const WrapperFooter = styled.div`
  @media ${device.mobile} {
    border: 1px solid ${({ theme }) => theme.tokens.gray[800]};
  }
  max-width: 1140px;
  margin: 0 auto;
  display: block;

  @media ${device.mobile} {
    padding-left: 22px;
    padding-right: 22px;
  }
`;

const Press = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

const Content = styled.div`
  padding-bottom: 60px;
`;

const Title = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 42px;
  color: ${({ theme }) => theme.tokens.blue[800]};
  margin: 0 0 13px 0;
`;

const SubTitle = styled.p`
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: ${({ theme }) => theme.tokens.gray[1900]};
  margin: 0;
  max-width: 280px;
  line-height: 20px;
`;

const Medias = styled.div`
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 80px 0;

  @media ${device.mobile} {
    margin-bottom: 0;
  }
`;

const Media = styled.div`
  &:not(:first-child) {
    margin-left: 18px;
  }
  &:not(:last-child) {
    margin-right: 18px;
  }
  &:nth-child(3) {
    margin-top: 12px;
  }
`;

const GroupLinks = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  @media ${device.mobile} {
    flex-direction: column;
  }
  justify-content: space-between;
  padding: 32px 0;
`;

const About = styled.div`
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;

  @media ${device.mobile} {
    flex-direction: column;
    margin-bottom: 40px;
  }
`;

const Link = styled.span`
  border-radius: 100px;
  color: ${({ theme }) => theme.tokens.blue[800]};
  font-size: 14px;
  font-weight: 500;
  padding: 12px 24px;
  text-decoration: none;
  &:hover {
    background-color: ${({ theme }) => theme.tokens.gray[1050]};
  }

  @media ${device.mobile} {
    margin: 12px 0;
    text-align: center;
  }
`;

const Social = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
`;

const Share = styled.ul`
  width: 34px;
  height: 34px;
  border: 1px solid ${({ theme }) => theme.tokens.blue[800]};
  border-radius: 50%;
  display: inline-block;
  line-height: 37px;
  text-align: center;
  color: ${({ theme }) => theme.tokens.blue[800]};
  &:hover {
    background-color: ${({ theme }) => theme.tokens.gray[1050]};
  }
  &:not(:last-child) {
    margin-right: 22px;
  }
`;

export default {
  MainFooter,
  WrapperFooter,
  Press,
  Content,
  Title,
  SubTitle,
  Medias,
  Media,
  GroupLinks,
  About,
  Link,
  Social,
  Share,
};
