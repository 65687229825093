import Styles from '@components/copy/style';
import useCurrentYear from '@hooks/use-current-year';
import { defaultTheme } from '@styles/theme';

type CopyProps = {
  color?: string;
  fontSize?: string;
  fontSizeXs?: string;
  lineHeight?: string;
  margin?: string;
  marginXs?: string;
  padding?: string;
};
export default function Copy({ color, fontSizeXs, fontSize, lineHeight, margin, marginXs, padding }: CopyProps) {
  const currentYear = useCurrentYear();
  return (
    <Styles.Copy
      color={color}
      fontSize={fontSize}
      fontSizeXs={fontSizeXs}
      lineHeight={lineHeight}
      margin={margin}
      marginXs={marginXs}
      padding={padding}
    >{`© Copyright ${currentYear} Afya - Todos os direitos reservados`}</Styles.Copy>
  );
}

Copy.defaultProps = {
  color: defaultTheme.tokens.gray[1200],
  fontSize: '14px',
  fontSizeXs: '14px',
  lineHeight: 'unset',
  margin: '0',
  marginXs: '0',
  padding: '0',
};
