import styled from 'styled-components';
import { device } from '@styles/media';

const Column = styled.div`
  height: auto;
`;

const HorizontalLine = styled.hr`
  background-color: ${({ theme }) => theme.tokens.gray[700]};
  border: 0;
  border-top: 1px solid ${({ theme }) => theme.tokens.gray[700]};
  margin-left: -10px;
  margin-right: -10px;
  @media ${device.mobile} {
    margin-bottom: 1px;
    margin-top: 1px;
  }
`;

const FeaturesIcon = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0 20px;

  div {
    height: 36px;
    display: flex;
    align-items: center;

    @media ${device.mobile} {
      align-items: unset;
      justify-content: right;
    }
  }

  @media ${device.mobile} {
    font-size: 14px;
    padding: 1px;
    text-align: right;
    margin: 16px 0 16px auto;
    display: block;

    div {
      height: 22px;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

const Features = styled.h4`
  color: ${({ theme }) => theme.tokens.gray[1900]};
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  margin: 20px 0 20px 0;

  @media ${device.mobile} {
    font-size: 16px;
    justify-content: right;
    margin: 16px auto 16px auto;
    height: 24px;
  }
`;

const FeaturesHighlight = styled.h4`
  color: ${({ theme }) => theme.tokens.gray[1900]};
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  height: 36px;

  @media ${device.mobile} {
    font-size: 16px;
    text-align: right;
    margin: 16px auto 16px auto;
    height: 24px;
  }
`;

export default {
  Column,
  HorizontalLine,
  Features,
  FeaturesIcon,
  FeaturesHighlight,
};
