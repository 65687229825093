import { snowplowButtonClick } from '@analytics/snowplow/events';
import { Button } from '@pebmed/storybook-react';
import Styles from '@screens/landing-page-default/components/hero/style';
import General from '@styles/general';
import { defaultTheme } from '@styles/theme';

export default function Hero({ state }) {
  const handleAnchor = () => {
    const heightMenu = 90;
    const offset = state.offsetState?.features;
    window.scrollTo({ top: offset - heightMenu, behavior: 'smooth' });
  };

  return (
    <General.Wrapper bottom="none" data-testid="hero">
      <Styles.ContentHero>
        <Styles.Logo>
          <img src="/images/logo-wb-sm.svg" alt="Logo Whitebook" width="56" height="27" />
        </Styles.Logo>
        <Styles.Title>Whitebook.</Styles.Title>
        <Styles.Description>De médicos para médicos, melhorando a sua tomada de decisão clínica.</Styles.Description>
        <Styles.Link as="a" href="/cadastro">
          <Button
            onClick={() => {
              snowplowButtonClick('signup', '/planos', 'hero');
            }}
            backgroundColor={defaultTheme.tokens.blue[800]}
            width="190px"
            height="50px"
            data-testid="signup"
          >
            Cadastrar
          </Button>
        </Styles.Link>
      </Styles.ContentHero>
      <Styles.Action onClick={handleAnchor} data-testid="pageDown">
        <img src="/images/arrow.svg" alt="icone seta" width="32" height="40" loading="lazy" />
      </Styles.Action>
    </General.Wrapper>
  );
}
