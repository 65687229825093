import { useContext, useEffect, useRef } from 'react';

import { ContextConsumer } from '@context/index';
import { actionSetOffsetComponents } from '@context/offset/actions';
import { Toast } from '@pebmed/storybook-react';
import { useGetOffers } from '@screens/hooks/use-get-offers';
import Download from '@screens/landing-page-default/components/download';
import Features from '@screens/landing-page-default/components/features';
import Hero from '@screens/landing-page-default/components/hero';
import Plans from '@screens/landing-page-default/components/plans';
import Testimonials from '@screens/landing-page-default/components/testimonials';
import Styles from '@screens/landing-page-default/styles';
import { TOAST_FIXED_WIDTH } from '@shared/constants/toasts';
import Footer from '@components/footer';
import { defaultTheme } from '@styles/theme';
import HeroImage from '@screens/landing-page-default/components/heroimage';

export default function LandingPageDefaultScreen() {
  const { state, dispatch } = useContext(ContextConsumer);
  const featuresRef = useRef(null);
  const testimonialsRef = useRef(null);
  const { offers, error, loadPlanList } = useGetOffers('LandingPage');

  const handleOffsetState = () => {
    dispatch(
      actionSetOffsetComponents({
        hero: null,
        features: featuresRef.current,
        testimonials: testimonialsRef.current,
        isDark: false,
      }),
    );
  };

  useEffect(() => {
    loadPlanList();
  }, []);

  useEffect(() => {
    handleOffsetState();
  }, [featuresRef, testimonialsRef]);

  return (
    <>
      <Styles.Content data-testid="landingPage">
        {error && (
          <Toast error width={TOAST_FIXED_WIDTH}>
            {error}
          </Toast>
        )}
        <HeroImage />
        <Hero state={state} />
        <Styles.Anchor ref={featuresRef}>
          <Features bgColor={defaultTheme.tokens.gray[100]} />
        </Styles.Anchor>
        <Plans offersData={offers} />
        <Styles.Anchor sticky ref={testimonialsRef}>
          <Testimonials bgColor={defaultTheme.tokens.blue[800]} />
        </Styles.Anchor>
        <Download bgColor={defaultTheme.tokens.gray[100]} />
      </Styles.Content>
      <Footer />
    </>
  );
}
