import { PageHeadTagTypes } from '@head-tags/page-tags/types';
import { PageHeaderTypes, PageLayoutTypes } from '@layout/types';
import LandingPageDefaultScreen from '@screens/landing-page-default';

export default function LandingPageScreen() {
  return <LandingPageDefaultScreen />;
}

export async function getStaticProps() {
  return {
    props: {
      layout: PageLayoutTypes.OnlyChildren,
      header: PageHeaderTypes.Landing,
      tags: PageHeadTagTypes.Landing,
    },
  };
}
