import { snowplowButtonClick } from '@analytics/snowplow/events';
import Styles from '@screens/landing-page-default/components/download/style';
import { StyledWrapperProps } from '@screens/landing-page-default/types';
import General from '@styles/general';

export default function Download({ bgColor, isDarkMode }: StyledWrapperProps) {
  return (
    <General.FullWrapper bgColor={bgColor}>
      <General.Wrapper padding>
        <Styles.DownloadWrapper data-testid="download">
          <img src="/images/whitebook-app.webp" alt="App whitebook" loading="lazy" width={532} height={577} />
          <Styles.WrapDownloads>
            <Styles.SubTitleDownload isDarkMode={isDarkMode}>
              Baixe agora e aproveite os nossos conteúdos
            </Styles.SubTitleDownload>
            <Styles.Platform isDarkMode={isDarkMode}>
              Disponível para download nas plataformas Android e iOS:
            </Styles.Platform>

            <Styles.WrapLinks>
              <Styles.Link
                as="a"
                href="https://play.google.com/store/apps/details?id=com.medprescricao"
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  snowplowButtonClick('store_download', '/planos', 'android');
                }}
                data-testid="link-android"
              >
                <img
                  src="/images/download-android.webp"
                  alt="Download whitebook Android"
                  loading="lazy"
                  width={158}
                  height={46}
                />
              </Styles.Link>
              <Styles.Link
                as="a"
                href="https://apps.apple.com/br/app/whitebook-cid-10-e-medicina/id638521744"
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  snowplowButtonClick('store_download', '/planos', 'ios');
                }}
                data-testid="link-ios"
              >
                <img
                  src="/images/download-ios.webp"
                  alt="Download whitebook iOS"
                  loading="lazy"
                  width={158}
                  height={46}
                />
              </Styles.Link>
            </Styles.WrapLinks>
          </Styles.WrapDownloads>
        </Styles.DownloadWrapper>
      </General.Wrapper>
    </General.FullWrapper>
  );
}
