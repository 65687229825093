import { OffersModel } from '@remote-data/load-offers/types';
import { useState } from 'react';
import { loggerWarn, loggerInfo } from '@shared/logger';
import UseCaseLoadOffers from '@use-cases/load-offers';

interface HooksReturn {
  offers: OffersModel;
  error: string;
  isLoading: boolean;
  loadPlanList(): void;
}

export const useGetOffers = (fromScreen: string): HooksReturn => {
  const [offers, setOffers] = useState<OffersModel>();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const loadPlanList = async () => {
    try {
      setIsLoading(true);
      setError('');

      const offersModel = await UseCaseLoadOffers.run();
      loggerInfo(`${fromScreen}Screen`, 'loadPlanList', { offersModel });

      setOffers(offersModel);
    } catch (err) {
      loggerWarn(`${fromScreen}Screen`, 'loadPlanList', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return { offers, error, isLoading, loadPlanList };
};
