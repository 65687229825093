import styled, { css } from 'styled-components';
import { device } from '@styles/media';
import { GeneralProps } from './types';

const Wrapper = styled.section`
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  padding-left: 50px;
  padding-right: 50px;
  position: relative;
  padding-bottom: 120px;

  flex-direction: ${({ reverse }: GeneralProps) => {
    return reverse ? 'row-reverse' : 'column';
  }};

  padding-top: ${({ padding }: GeneralProps) => {
    return padding ? '100px' : 0;
  }};

  @media ${device.mobile} {
    padding-right: 22px;
    padding-bottom: 80px;
    padding-left: 22px;
    overflow-x: hidden;

    padding-top: ${({ padding }: GeneralProps) => {
      return padding ? '80px' : 0;
    }};

    padding-top: ${({ top }: GeneralProps) => {
      return top === 'none' && 0;
    }};

    padding-bottom: ${({ bottom }: GeneralProps) => {
      return bottom === 'none' && 0;
    }};

    ${({ mobile }: GeneralProps) => {
      return (
        mobile === 'hero' &&
        css`
          min-height: 895px;
        `
      );
    }};

    ${({ mobile }: GeneralProps) => {
      return (
        mobile === 'reliability' &&
        css`
          min-height: 885px;
        `
      );
    }};
  }

  @media ${device.mobileSmall} {
    ${({ mobile }: GeneralProps) => {
      return (
        mobile === 'reliability' &&
        css`
          min-height: 985px;
        `
      );
    }};
  }
`;

const FullWrapper = styled.section`
  width: 100%;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);

  background-color: ${({ bgColor }: GeneralProps) => {
    return bgColor || 'none';
  }};

  ${({ bgColor }: GeneralProps) => {
    return (
      bgColor === 'gradient' &&
      css`
        background: linear-gradient(180deg, #e1dee8 0%, rgba(244, 243, 246, 0) 123.22%);
        opacity: 0.25;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
      `
    );
  }};

  ${({ background }: GeneralProps) =>
    background &&
    css`
      background-image: url('/images/border-slice.png');
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: contain;

      @media ${device.mobile} {
        padding-top: 56px;
      }
    `};
`;

const SubTitle = styled.h2`
  font-size: 33px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.tokens.blue[800]};
  display: block;
  margin: 0;
  font-weight: normal;
  padding-bottom: 80px;
  max-width: ${({ width }: GeneralProps) => {
    return width || 'none';
  }};

  @media ${device.mobile} {
    font-size: 28px;
    line-height: 34px;
    padding: 60px 0;
  }
`;

export default {
  Wrapper,
  FullWrapper,
  SubTitle,
};
