import styled from 'styled-components';
import { device } from '@styles/media';
import General from '@styles/general';
import { StyledWrapperProps } from '@screens/landing-page-default/types';

const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

const Card = styled.div<Partial<StyledWrapperProps>>`
  border: 1px solid ${({ isDarkMode, theme }) => (isDarkMode ? theme.tokens.gray[800] : theme.tokens.gray[1000])};
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 240px;
  height: 330px;
  padding: 34px;
  background: ${({ isDarkMode, theme }) => (isDarkMode ? 'transparent' : theme.tokens.gray[100])};

  @media ${device.mobile} {
    width: 100%;
    height: 270px;
    margin-bottom: 20px;
  }
`;

const Feature = styled.p<Partial<StyledWrapperProps>>`
  margin: 0;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: -0.02em;
  color: ${({ isDarkMode, theme }) => isDarkMode && theme.tokens.gray[100]};
  @media ${device.mobile} {
    width: 160px;
  }
`;

const SubTitle = styled(General.SubTitle)<Partial<StyledWrapperProps>>`
  color: ${({ isDarkMode, theme }) => (isDarkMode ? theme.tokens.gray[100] : theme.tokens.blue[800])};
`;

export default {
  CardContainer,
  Card,
  Feature,
  SubTitle,
};
