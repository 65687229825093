import styled from 'styled-components';

import { StyledAnchorProps } from '@screens/landing-page-default/types';

const Content = styled.main`
  min-height: 100vh;
  margin-top: 105px;
`;

const Anchor = styled.section<StyledAnchorProps>`
  top: 0;
  position: ${({ sticky }) => {
    return sticky ? 'sticky' : 'static';
  }};
`;

export default {
  Content,
  Anchor,
};
