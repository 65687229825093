import styled, { css } from 'styled-components';

import { TestimonialsProps } from '@screens/landing-page-default/components/testimonials/types';
import { device } from '@styles/media';

const SubTitleTestimonial = styled.h2`
  font-size: 33px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.tokens.gray[100]};
  display: block;
  margin: 0 auto;
  text-align: center;
  font-weight: normal;
  padding-bottom: 20px;
  max-width: 400px;
  padding-top: 120px;

  @media ${device.mobile} {
    font-size: 28px;
  }
`;

const Testimonial = styled.p`
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.tokens.gray[100]};
  display: block;
  margin: 0 auto;
  text-align: center;
  font-weight: normal;
  max-width: 812px;
  height: 178px;

  @media ${device.mobile} {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    padding-left: 14px;
    padding-right: 14px;
    min-height: 120px;
  }
`;

const Quote = styled.img`
  position: relative;
  left: 0;
  top: 20px;
  height: auto;
  max-width: 66px;

  @media ${device.desktop} {
    left: 100px;
    max-width: unset;
    top: 30px;
  }
`;

const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  width: 130px;
  height: 50px;
  margin: 50px auto 0 auto;
`;

const Control = styled.span`
  width: 50px;
  height: 50px;
  border: 1px solid ${({ theme }) => theme.tokens.gray[100]};
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.2s ease-in;
  background-repeat: no-repeat;
  background-position: center;

  ${({ forward }: TestimonialsProps) =>
    forward &&
    css`
      background-image: url(/images/ico-forward.svg);
      &:hover {
        background-image: url(/images/ico-forward-hover.svg);
      }
    `}

  ${({ back }: TestimonialsProps) =>
    back &&
    css`
      background-image: url(/images/ico-back.svg);
      &:hover {
        background-image: url(/images/ico-back-hover.svg);
      }
    `}

  &:hover {
    background-color: ${({ theme }) => theme.tokens.gray[100]};
    border: 1px solid ${({ theme }) => theme.tokens.blue[800]};
  }

  img {
    margin-top: 18px;
  }
`;

const Persona = styled.div`
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.tokens.gray[100]};
  display: block;
  padding-top: 20px;

  @media ${device.mobile} {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
  }
`;

export default {
  SubTitleTestimonial,
  Testimonial,
  Navigation,
  Control,
  Persona,
  Quote,
};
