import styled from 'styled-components';
import { device } from '@styles/media';

const ContentHero = styled.div`
  max-width: 465px;
  padding-top: 95px;
  padding-bottom: 120px;

  @media ${device.mobile} {
    padding-top: 30px;
  }
`;

const Title = styled.h1`
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 78px;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.tokens.blue[800]};

  @media ${device.mobile} {
    font-weight: 500;
    font-size: 32px;
    line-height: 42px;
  }
`;

const Description = styled.span`
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 52px;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.tokens.gray[1900]};
  margin-bottom: 30px;

  @media ${device.mobile} {
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
    max-width: 233px;
    padding-top: 10px;
    margin-bottom: 20px;
  }
`;

const Action = styled.span`
  display: flex;
  margin: 0 auto;
  cursor: pointer;

  @media ${device.mobile} {
    margin-top: 230px;
    margin-bottom: 110px;
  }
`;

const Link = styled.span`
  text-decoration: none;
  color: inherit;

  button {
    display: inline-block;
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  margin-bottom: 1rem;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.tokens.gray[700]};

  & > img {
    width: 34px;
    height: auto;
  }

  @media ${device.desktop} {
    width: 71px;
    height: 71px;

    & > img {
      width: 3.5rem;
    }
  }
`;

export default {
  ContentHero,
  Title,
  Description,
  Action,
  Link,
  Logo,
};
