import styled from 'styled-components';
import { device } from '@styles/media';

const HeroImage = styled.img`
  height: auto;
  position: absolute;
  right: 0;
  @media ${device.mobile} {
    max-width: 316px;
    top: 469px;
  }
`;

export default {
  HeroImage,
};
