import Styles from '@screens/landing-page-default/components/heroimage/style';

export default function HeroImage() {
  return (
    <picture>
      <source srcSet="/images/bg-home-mobile.webp" media="(max-width: 768px)" sizes="(max-width: 768px) 316px, 654px" />
      <source srcSet="/images/bg-home-desktop.webp" media="(min-width: 769px)" sizes="(min-width: 769px) 654px" />
      <Styles.HeroImage src="/images/bg-home-desktop.webp" alt="Background" width="654" height="499" />
    </picture>
  );
}
