import { useState } from 'react';

import { snowplowButtonClick, snowplowSubscribe } from '@analytics/snowplow/events';
import { Button, CheckMarkIcon } from '@pebmed/storybook-react';
import { PlanModel } from '@remote-data/load-offers/types';
import Styles from '@screens/landing-page-default/components/plans/components/column-premium/style';
import PlanFeatureTableColumn from '@screens/landing-page-default/components/plans/components/feature-table/column/index';
import PlanFeatureTableHeader from '@screens/landing-page-default/components/plans/components/feature-table/header/index';
import { loggerInfo } from '@shared/logger';
import { defaultTheme } from '@styles/theme';

export default function ColumnPremium({ offersData, cash }) {
  const [isMoreDetailsClickedPremium, setisMoreDetailsClickedPremium] = useState(false);

  const monthly = offersData?.planList?.filter((plan) => plan?.period === 'monthly')[0];
  const annual = offersData?.planList?.filter((plan) => plan?.period === 'annually' && !plan?.splittable)[0];

  const handlePlan = () => {
    loggerInfo('PlanCardComponent', 'handlePlan', cash);
    snowplowButtonClick('subscribe_button', '/planos', 'annually_onetime');
    snowplowSubscribe('web', 'plan_choice', 1, cash.id);
    handleNavigationChosenPlan(cash);
  };

  const handleNavigationChosenPlan = (plan: PlanModel) => {
    window.location.href = `${process.env.NEXT_PUBLIC_PAYMENT_METHOD_CHECKOUT}?planid=${plan?.storeId}&utm_source=${process.env.NEXT_PUBLIC_APP_CODE_BACKEND}`;
  };

  const checkMarkIcon = <CheckMarkIcon size={36} strokeColor={defaultTheme.tokens.pink[200]} />;

  return (
    <Styles.ColumnRecommendedPlan>
      <Styles.DivTag>
        <Styles.Tag>Recomendado</Styles.Tag>
      </Styles.DivTag>
      <Styles.PlanInfoDiv>
        <Styles.Type>Premium</Styles.Type>
        <Styles.Price data-testid="monthly">
          R$<span>{monthly?.price?.total}</span>/{monthly?.price?.exhibitionalLabel}
        </Styles.Price>
        <Styles.Text>Ou</Styles.Text>
        <Styles.PriceOneTime data-testid="annually">
          R$<h3>{annual?.price?.total}*</h3>/ano
        </Styles.PriceOneTime>
        <Styles.Description1>À vista ou R$ {offersData?.basePrice?.annually} até 12x </Styles.Description1>
        <Styles.Description2>*12 meses pelo preço de 11</Styles.Description2>
      </Styles.PlanInfoDiv>
      <Styles.DivBtn>
        <Button
          height="50px"
          width="118px"
          onClick={() => {
            handlePlan();
          }}
          data-testid="selectFullButton"
        >
          Selecionar
        </Button>
      </Styles.DivBtn>
      <Styles.FeatureDivDesktop>
        <Styles.BlankSpaceFeature />
        <PlanFeatureTableColumn
          featureRow1="Ilimitado"
          featureRow2={checkMarkIcon}
          featureRow3={checkMarkIcon}
          featureRow4={checkMarkIcon}
          featureRow5="+ 1.400"
          featureHighlight1="+ 3.000"
          featureHighlight2="+ 180"
        />
      </Styles.FeatureDivDesktop>
      {isMoreDetailsClickedPremium ? (
        <Styles.Div />
      ) : (
        <Styles.BtnMaisDetalhes onClick={() => setisMoreDetailsClickedPremium(true)}>
          Mais detalhes
        </Styles.BtnMaisDetalhes>
      )}
      {isMoreDetailsClickedPremium ? (
        <Styles.FeatureDivMobile>
          <Styles.FeatureTxtPlanColumn>Características do plano</Styles.FeatureTxtPlanColumn>
          <Styles.GridMobile>
            <Styles.GridItem>
              <PlanFeatureTableHeader
                featureRow1="Acesso"
                featureRow2="Medicamentos"
                featureRow3="Código CID, TUSS e SUS"
                featureRow4="Fluxogramas interativos"
                featureRow5="Prescrições"
                featureHighlight1="Condutas clínicas"
                featureHighlight2="Calculadoras e escores"
              />
            </Styles.GridItem>
            <Styles.GridItem>
              <PlanFeatureTableColumn
                featureRow1="Ilimitado"
                featureRow2={checkMarkIcon}
                featureRow3={checkMarkIcon}
                featureRow4={checkMarkIcon}
                featureRow5="+ 1.400"
                featureHighlight1="+ 3.000"
                featureHighlight2="+ 180"
              />
            </Styles.GridItem>
          </Styles.GridMobile>
          <Styles.BtnReduzir onClick={() => setisMoreDetailsClickedPremium(false)}>Reduzir</Styles.BtnReduzir>
        </Styles.FeatureDivMobile>
      ) : (
        <Styles.BlankSpaceFeature />
      )}
    </Styles.ColumnRecommendedPlan>
  );
}
